<template>
    <div class="xl:container my-5 lg:px-5 sm:px-2 mx-auto">

        <div class="flex">
            <button
                :key="'tab-'+idx"
                v-for="(component, idx) in tabs"
                @click="tab = idx"
                class="px-6 py-3 mr-2 bg-gray-500"
                :class="{'bg-green-400':tab === idx}"
            >{{component.name}}</button>
        </div>

        <component :is="selected.component"></component>


    </div>
</template>
<script>


export default  {
    data:()=> ({
        tab:0,
        tabs:[
            {
                name:'Нераспознанные платежи',
                component:()=> import('@/components/user/admin/payments/PaymentResolve')
            },
            {
                name:'Списание денежных средств',
                component:()=> import('@/components/user/admin/payments/GetMoney')
            },
            {
                name:'Зачисление денежных средств',
                component:()=> import('@/components/user/admin/payments/PutMoney')
            },
            {
              name:'Оплаты ЕРИП и Webpay',
              component:()=> import('@/components/user/admin/payments/UnusedPayments')
            },
            {
              name:'Оплаты физ.лиц',
              component:()=> import('@/components/user/admin/payments/SearchPayment')
            }
        ]
    }),
    computed:{
        selected(){
            return this.tabs[this.tab]
        }
    },
    created() {
        this.$store.dispatch('admin/getContractsAllSimple')

    },

}
</script>


<style scoped>

</style>